import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Townhall meeting in Kentucky turns tables on polarization`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Residents of Bowling Green, Kentucky, used Polis to find agreement on local issues despite politicians' attempt to sow discord.
`}<strong parentName="p">{`When:`}</strong>{` 2018
`}<strong parentName="p">{`Where:`}</strong>{` Kentucky, USA
`}<strong parentName="p">{`Size:`}</strong>{` 2000`}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` When a medium sized city in Kentucky was ravaged and divided by national hot button issues, town residents used Polis to identify local issues on which there was consensus, gain an understanding of their collective will, and then use the documentation provided by Polis to hold their officials accountable to act on that consensus. Thanks to media partner, Bowling Green Daily News, this case had very high participation rates relative to total population size: 2000 out of 65000 of the population participated, or 3%.`}</p>
    <h4>{`Links:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{``}<a parentName="strong" {...{
              "href": "/Facilitation-partner:-American-Assembly",
              "title": "Facilitation partner: American Assembly"
            }}>{`Facilitation partner: American Assembly`}</a>{` `}<a parentName="strong" {...{
              "href": "https://americanassembly.org/"
            }}>{`https://americanassembly.org/`}</a></strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Their project page: `}<a parentName="li" {...{
              "href": "http://www.civic-assembly.org"
            }}>{`http://www.civic-assembly.org`}</a></li>
          <li parentName="ul">{`Direct link: `}<a parentName="li" {...{
              "href": "http://www.civic-assembly.org/bowling-green-report/"
            }}>{`http://www.civic-assembly.org/bowling-green-report/`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{``}<a parentName="strong" {...{
              "href": "/Media-Partner:-Bowling-Green-Daily-News",
              "title": "Media Partner: Bowling Green Daily News"
            }}>{`Media Partner: Bowling Green Daily News`}</a>{` `}<a parentName="strong" {...{
              "href": "https://www.bgdailynews.com/"
            }}>{`https://www.bgdailynews.com/`}</a></strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Their project page: `}<a parentName="li" {...{
              "href": "http://www.bgdailynews.com/civicassembly/"
            }}>{`http://www.bgdailynews.com/civicassembly/`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Open data: `}<a parentName="p" {...{
            "href": "https://github.com/compdemocracy/openData/tree/master/american-assembly.bowling-green"
          }}>{`https://github.com/compdemocracy/openData/tree/master/american-assembly.bowling-green`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Video of the event: `}<a parentName="p" {...{
            "href": "https://www.facebook.com/bgdailynews/videos/vb.27436346345/10155923182626346/?type=2&theater"
          }}>{`https://www.facebook.com/bgdailynews/videos/vb.27436346345/10155923182626346/?type=2&theater`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Polis Report: `}<a parentName="p" {...{
            "href": "https://pol.is/report/r2xcn2cdbmrzjmmuuytdk"
          }}>{`https://pol.is/report/r2xcn2cdbmrzjmmuuytdk`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Raw data `}<a parentName="p" {...{
            "href": "https://github.com/compdemocracy/openData/tree/master/bowling-green.american-assembly"
          }}>{`https://github.com/compdemocracy/openData/tree/master/bowling-green.american-assembly`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Media:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.bgdailynews.com/news/first-ever-civic-assembly-gives-residents-chance-to-be-heard/article_0a17254e-a8bb-5f4f-884f-9d0617ab9c08.html"
            }}>{`http://www.bgdailynews.com/news/first-ever-civic-assembly-gives-residents-chance-to-be-heard/article_0a17254e-a8bb-5f4f-884f-9d0617ab9c08.html `}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://web.archive.org/web/20210414093745/https://civichall.org/civicist/testing-tech-consensus-purple-town/"
            }}>{`https://web.archive.org/web/20210414093745/https://civichall.org/civicist/testing-tech-consensus-purple-town/`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      